import React from 'react';
import Slider from 'react-slick';

import CustomButton from '../components/CustomButton';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './HomeSliderSection.css';

const CustomPrevArrow = ({ onClick }) => {
  return (
    <div className="home-slider__arrow home-slider__arrow--prev" onClick={onClick}>
      <img className="" src="/img/icon-slider-left-arrow.svg" />
    </div>
  );
};

const CustomNextArrow = ({ onClick }) => {
  return (
    <div className="home-slider__arrow home-slider__arrow--next" onClick={onClick}>
      <img className="" src="/img/icon-slider-right-arrow.svg" />
    </div>
  );
};

const HomeSliderSection = () => {
  var settings = {
    // dots: true,
    // infinite: true,
    arrows: true,
    speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <section className="home-slider-section">
      <Slider {...settings}>
        <div>
          <div className="home-sider__slide" style={{backgroundImage: `url('/img/aircraft-maintenance-technician-coordinating-air-support-provide-assistance.png')`}}>
            <div className="container">
              <div className="home-slider__slide-wrap">
                <h3 className="home-slider__sub-title">deftechlink</h3>
                <h1 className="home-slider__main-title">DefTechLink is your bridge to defense technology opportunity</h1>
                <p className="home-slider__desc">Deftechlink is your all-in-one platform for thriving in the world of global defense technology. We streamline your access to international government procurement and research opportunities, making it easier than ever to navigate allied defense markets.</p>
                <CustomButton label="explore more" type="button" isFullWidth={false} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="home-sider__slide" style={{backgroundImage: `url('/img/aircraft-maintenance-technician-coordinating-air-support-provide-assistance.png')`}}>
            <div className="container">
              <div className="home-slider__slide-wrap">
                <h3 className="home-slider__sub-title">deftechlink</h3>
                <h1 className="home-slider__main-title">DefTechLink is your bridge to defense technology opportunity</h1>
                <p className="home-slider__desc">Deftechlink is your all-in-one platform for thriving in the world of global defense technology. We streamline your access to international government procurement and research opportunities, making it easier than ever to navigate allied defense markets.</p>
                <CustomButton label="explore more" type="button" isFullWidth={false} />
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default HomeSliderSection;