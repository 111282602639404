import React from 'react';
import { Link } from 'react-router-dom';

import './MainFooter.css';

const MainFooter = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="container">
          <div className="footer-wrap">
            <Link className="main-logo-link" to="/">
              <img className="main-footer-logo-img" src="/img/logo-white.svg" alt="logo image" />
            </Link>
            <div className="footer-main-links">
              <Link className="footer-main-link" to="/">home</Link>
              <Link className="footer-main-link" to="/about">about</Link>
              <Link className="footer-main-link" to="/services">services</Link>
              <Link className="footer-main-link" to="/contact">contact us</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright-container">
        <div className="container">
          <div className="footer-copyright-wrap">
            <p className="footer-copyright-desc">Copyright©deftechlink. All rights reserved</p>
            <div className="footer-social-links">
              <Link className="footer-social-link" to="">
                <img className="" src="/img/icon-google.svg" alt="google icon" />
              </Link>
              <Link className="footer-social-link" to="">
                <img className="" src="/img/icon-twitter.svg" alt="twitter icon" />
              </Link>
              <Link className="footer-social-link" to="">
                <img className="" src="/img/icon-instagram.svg" alt="instagram icon" />
              </Link>
              <Link className="footer-social-link" to="">
                <img className="" src="/img/icon-linkedin.svg" alt="linkedin icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;