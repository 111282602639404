export const getPlanFromId = (plans, planId) => {

  if (!plans) return null;

  const filteredPlans1 = plans['dtl'].filter((plan) => plan.id == planId);

  if (filteredPlans1.length != 0) {
    return filteredPlans1[0];
  }

  const filteredPlans2 = plans['marketplace'].filter((plan) => plan.id == planId);

  if (filteredPlans2.length != 0) {
    return filteredPlans2[0];
  }

  return null;
};

export const getSubscriptionsFromType = (plans, type) => {

  if (!plans || !plans.hasOwnProperty(type)) return [];

  return plans[type].map((plan) => {
    return {
      value: plan['id'],
      label: plan['level']
    }
  });
};

export const clearCache = () => {
  localStorage.clear();
  sessionStorage.clear();
  
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}