import React from 'react';
import { Link } from 'react-router-dom';

import './BasicPageTemplate.css';

const BasicPageTemplate = ({ children }) => {
  return (
    <div className="basic-page-container">
      <img className="top-left-img" src="/img/top-left.svg" alt="top left image"/>
      <img className="bottom-right-img" src="/img/bottom-right.svg" alt="bottom right image" />
      <div className="basic-page-content">
        <Link to="/" className="logo-link">
          <img className="logo-img" src="/img/logo.svg" alt="logo image" />
        </Link>
        { children }
      </div>
    </div>
  );
};

export default BasicPageTemplate;