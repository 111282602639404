import React, { useState, useContext } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import API from '../utils/api';
import CustomInput from '../components/CustomInput';
import { currency, countries } from '../constants/constants';
import CustomButton from '../components/CustomButton';
import './CheckoutSection.css';

const CheckoutSection = ({ plan, paymentIntentId }) => {

  const stripe = useStripe();
  const elements = useElements();
  
  const [isCheckoutProcessing, setIsCheckoutProcessing] = useState(false);

  const [isCouponAvailable, setIsCouponAvailable] = useState(false);
  const [isCouponError, setIsCouponError] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const [coupon, setCoupon] = useState('');

  const originalPrice = plan.price;
  const taxPrice = 0.6;
  const [discountPrice, setDiscountPrice] = useState(0);
    
  const handleInputChange = (name, value) => {
    // setFormData({ ...formData, [name]: value });
  };

  const doCheckout = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setIsCheckoutProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
        payment_method_data: {
          billing_details: {
            address: {
              country: "US",
              postal_code: '81009'
            },
          },
        },
      }
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      // setMessage(error.message);

    } else {
      // setMessage("An unexpected error occured.");
    }

    setIsCheckoutProcessing(false);
    window.location.href = '/login';
  };

  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
  };

  const applyCoupon = async () => {

    if (coupon == '') {
      return;
    }

    setIsCouponAvailable(false);
    setIsCouponError(false);
    setCouponErrorMessage('');

    try {
      const response = await API.post('/api/payment/stripe/apply-coupon', {
        coupon_code: coupon,
        payment_intent_id: paymentIntentId
      });

      if (response.valid) {
        setIsCouponAvailable(true);
        setDiscountPrice(response.discount_amount);

        // if (response.is_amount) {
        //   setDiscountPrice(response.discount / 100)

        // } else {
        //   setDiscountPrice(Math.trunc(originalPrice * response.discount) / 100)
        // }
      } else {
        setIsCouponAvailable(false);
        setIsCouponError(true);
        setCouponErrorMessage(response.message);
      }

    } catch(error) {
      setIsCouponAvailable(false);
      setIsCouponError(true);
      setCouponErrorMessage(error.message);
    }
  }

  const calcTotalPrice = () => {
    return (originalPrice - taxPrice - discountPrice).toFixed(2);
  }

  return (
    <section className="checkout-section">
      <div className="container">
        <div className="checkout-back-btn-wrap">
          <span className="back-btn">
            <span className="back-btn-wrap">
              <img className="back-btn__img" src="/img/icon-arrow-right.svg" alt="arrow right" />
              <span className="back-btn__text">Checkout</span>
            </span>
          </span>
        </div>
        <div className="checkout-section__content">
          <div className="checkout-section__main">
            <div className="checkout-section__plan">
              <div className="checkout-section__plan-price">
                <span className="checkout-section__plan-price-value">{ plan.price }</span>
                <span className="checkout-section__plan-price-curreny">{ currency }</span>
              </div>
              <div className="checkout-section__plan-desc">{ plan.description }</div>
              <span className="checkout-section__plan-level">{ plan.level }</span>
            </div>

            <div className="checkout-section__billing">
              <h2 className="checkout-section__block-title">Billing Address</h2>
              <div className="form-row">
                <div className="form-col">
                  <CustomInput
                    type="select"
                    options={countries}
                    placeholder="Country"
                    initialValue=""
                    name="country"
                    onInputChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col">
                  <CustomInput
                    type="text"
                    placeholder="Zip Code"
                    initialValue=""
                    name="zip_code"
                    onInputChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="checkout-section__payment">
              <h2 className="checkout-section__block-title">Payment Method</h2>
              <PaymentElement 
                options={{
                  fields: {
                    billingDetails: {
                      address: {
                        country: "never", // Hides country field
                        postalCode: "never", // Hides postal code field
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="checkout-section__side">
            <div className="checkout-section__summary">
              <h2 className="checkout-section__block-title">Summary</h2>
              <div className="checkout-section__summary-price-table">
                <div className="checkout-section__summary-price-row">
                  <span className="checkout-section__summary-price-label">Originial price:</span>
                  <span className="checkout-section__summary-price-value">${originalPrice.toFixed(2)}</span>
                </div>
                <div className="checkout-section__summary-price-row">
                  <span className="checkout-section__summary-price-label">Tax:</span>
                  <span className="checkout-section__summary-price-value">${taxPrice.toFixed(2)}</span>
                </div>
              </div>
              <div className="checkout-section__coupon-wrap">
                <div className="checkout-section__coupon-input-wrap">
                  <input type="text" className="checkout-section__coupon-input" onChange={handleCouponChange}/>
                  <span className="checkout-section__coupon-apply-btn" onClick={applyCoupon}>Apply</span>
                </div>
                <hr/>
                {
                  isCouponError && (
                    <div className="checkout-section__coupon-error">
                      <img className="checkout-section__coupon-error-icon" src="/img/icon-error.svg" alt="error icon" />
                      <span className="checkout-section__coupon-error-text">{couponErrorMessage}</span>
                    </div>
                  )
                }
              </div>
              <div className="checkout-section__summary-price-table">
                {
                  isCouponAvailable && (
                    <div className="checkout-section__summary-price-row">
                      <span className="checkout-section__summary-price-label">Discounts:</span>
                      <span className="checkout-section__summary-price-value value--color-black">-${discountPrice.toFixed(2)}</span>
                    </div>
                  )
                }
                <div className="checkout-section__summary-price-row">
                  <span className="checkout-section__summary-price-label">Total:</span>
                  <span className="checkout-section__summary-price-value value--color-black value--large">${calcTotalPrice()}</span>
                </div>
              </div>
              <div className="">
                <CustomButton label={isCheckoutProcessing ? "Processing..." : "complete checkout"} type="button" onClick={doCheckout}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckoutSection;