import React, { useEffect, useState } from 'react'

import UserPageTemplate from './templates/UserPageTemplate';
import UserCustomInput, { UserCustomCheckbox } from '../../components/user/UserCustomInput';

import './Search.css';
import ContentItem from '../../components/user/ContentItem';

const Accordion = ({ title, icon, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="user-search__filter-group">
      <div className="user-search__filter-group__head" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
        <div className="user-search__filter-group__title">{title}</div>
        <img
          className="user-search__filter-group__icon-img"
          src={icon || '/img/icon-arrow-down.svg'}
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
          alt="Toggle Icon"
        />
      </div>
      <div
        className="user-search__filter-group__body"
        style={{
          maxHeight: isOpen ? '500px' : '0',
          overflow: 'hidden',
          transition: 'max-height 0.3s ease',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const capabilityFilters = [
  {
    'label': 'Reseller',
    'value': 'reseller'
  },
  {
    'label': 'Consultant',
    'value': 'consultant'
  },
  {
    'label': 'Lobbysist',
    'value': 'lobbysist'
  },
  {
    'label': 'Systems Integrator',
    'value': 'systems-integrator'
  },
  {
    'label': 'Legal',
    'value': 'legal'
  }
];

const countryFilters = [
  {
    'label': 'US',
    'value': 'us'
  },
  {
    'label': 'UK',
    'value': 'uk'
  }
];

const opportunityTypeFilters = [
  {
    'label': 'RFI',
    'value': 'rfi'
  },
  {
    'label': 'RFP',
    'value': 'rfp'
  },
  {
    'label': 'R&D',
    'value': 'rd'
  },
  {
    'label': 'Tender',
    'value': 'tender'
  }
];

const statusFilters = [
  {
    'label': 'Closed',
    'value': 'closed'
  },
  {
    'label': 'Open',
    'value': 'open'
  }
];

const serviceFilters = [
  {
    'label': 'Army',
    'value': 'army'
  },
  {
    'label': 'Navy',
    'value': 'navy'
  },
  {
    'label': 'Airforce',
    'value': 'airforce'
  },
  {
    'label': 'Marine Corps',
    'value': 'marine-corps'
  },
  {
    'label': 'Special Operations',
    'value': 'special-operations'
  },
  {
    'label': 'Other',
    'value': 'other'
  }
];

const staticData = [
  {
    "id": 1,
    "title": "AI-Powered Surveillance Systems1",
    "ref_id_number": "RFP-2025-001",
    "capability": "Reseller, Surveillance",
    "funding": "Up to $1,000,000",
    "customer": "Department of Defense",
    "eligibility": "Open to all U.S. companies",
    "due_date": "2025-03-15",
    "submission_format": "Electronic PDF submission",
    "status": "Open",
    "type": "Tender",
    'service': 'Army',
    'country': 'US'
  },
  {
    "id": 2,
    "title": "AI-Powered Surveillance Systems2",
    "ref_id_number": "RFP-2025-001",
    "capability": "Artificial Intelligence, Consultant",
    "funding": "Up to $1,000,000",
    "customer": "Department of Defense",
    "eligibility": "Open to all U.S. companies",
    "due_date": "2025-03-15",
    "submission_format": "Electronic PDF submission",
    "status": "Closed",
    "type": "RFP",
    'service': 'Navy',
    'country': 'US'
  },
  {
    "id": 3,
    "title": "AI-Powered Surveillance Systems3",
    "ref_id_number": "RFP-2025-001",
    "capability": "Lobbysist, Surveillance",
    "funding": "Up to $1,000,000",
    "customer": "Department of Defense",
    "eligibility": "Open to all U.S. companies",
    "due_date": "2025-03-15",
    "submission_format": "Electronic PDF submission",
    "status": "Open",
    "type": "R&D",
    'service': 'Marine Corps',
    'country': 'US'
  },
  {
    "id": 4,
    "title": "AI-Powered Surveillance Systems4",
    "ref_id_number": "RFP-2025-001",
    "capability": "Artificial Intelligence, System Integrator",
    "funding": "Up to $1,000,000",
    "customer": "Department of Defense",
    "eligibility": "Open to all U.S. companies",
    "due_date": "2025-03-15",
    "submission_format": "Electronic PDF submission",
    "status": "Closed",
    "type": "RFI",
    'service': 'Special Operations',
    'country': 'UK'
  },
  {
    "id": 5,
    "title": "AI-Powered Surveillance Systems5",
    "ref_id_number": "RFP-2025-001",
    "capability": "Artificial Intelligence, Legal",
    "funding": "Up to $1,000,000",
    "customer": "Department of Defense",
    "eligibility": "Open to all U.S. companies",
    "due_date": "2025-03-15",
    "submission_format": "Electronic PDF submission",
    "status": "Open",
    "type": "RFP",
    'service': 'Airforce',
    'country': 'US'
  },
  {
    "id": 6,
    "title": "AI-Powered Surveillance Systems6",
    "ref_id_number": "RFP-2025-001",
    "capability": "Lobbysist, Surveillance",
    "funding": "Up to $1,000,000",
    "customer": "Department of Defense",
    "eligibility": "Open to all U.S. companies",
    "due_date": "2025-03-15",
    "submission_format": "Electronic PDF submission",
    "status": "Open",
    "type": "RFP",
    'service': 'Navy',
    'country': 'UK'
  }
];

const Search = () => {

  const [selectedCapabilityFilters, setSelectedCapabilityFilters] = useState([]);
  const [selectedCountryFilters, setSelectedCountryFilters] = useState([]);
  const [selectedOpportunityTypeFilters, setSelectedOpportunityTypeFilters] = useState([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState([]);
  const [selectedServiceFilters, setSelectedServiceFilters] = useState([]);
  const [filteredList, setFilteredList] = useState(staticData);

  const handleCheckboxInputChange = (name, label, value, checked) => {
    
    let funcSet = null;

    if (name == 'capability') {
      funcSet = setSelectedCapabilityFilters;

    } else if (name == 'country') {
      funcSet = setSelectedCountryFilters;

    } else if (name == 'opportunity_type') {
      funcSet = setSelectedOpportunityTypeFilters;

    } else if (name == 'status') {
      funcSet = setSelectedStatusFilters;

    } else if (name == 'service') {
      funcSet = setSelectedServiceFilters;
    }

    if (funcSet) {
      funcSet((prevSelectedFilters) =>
        checked
          ? [...prevSelectedFilters, label]
          : prevSelectedFilters.filter((item) => item !== label)
      );
    }
  };

  const handleSearchInputChange = (name, value) => {
    const timeoutId = setTimeout(() => {
      setFilteredList(staticData.filter((data) => {
        return (data.title.includes(value) || data.capability.includes(value) || data.eligibility.includes(value) || data.submission_format.includes(data));
      }));
    }, 500);

    return () => clearTimeout(timeoutId);
  }

  useEffect(() => {

    setFilteredList(staticData.filter((data) => {
      return (selectedCapabilityFilters.length == 0 || selectedCapabilityFilters.filter((filter) => data.capability.includes(filter)).length > 0) && 
      (selectedCountryFilters.length == 0 || selectedCountryFilters.filter((filter) => data.country.includes(filter)).length > 0) && 
      (selectedOpportunityTypeFilters.length == 0 || selectedOpportunityTypeFilters.filter((filter) => data.type.includes(filter)).length > 0) && 
      (selectedStatusFilters.length == 0 || selectedStatusFilters.filter((filter) => data.status.includes(filter)).length > 0) && 
      (selectedServiceFilters.length == 0 || selectedServiceFilters.filter((filter) => data.service.includes(filter)).length > 0);
    }));
  }, [selectedCapabilityFilters, selectedCountryFilters, selectedOpportunityTypeFilters, selectedStatusFilters, selectedServiceFilters])
  
  return (
    <UserPageTemplate>
      <div className="user-dashboard-content">
        <div className="user-search__body">
          <div className="user-search__side">
            <div className="user-search__filter">
              <div className="user-search__filter__title">Search By:</div>
              <Accordion title="Capability" icon="/img/icon-arrow-down.svg">
                <div className="user-search__filter-group__body">
                  <div className="user-search__filter-group__sub-title">Teammate Type</div>
                  {capabilityFilters.map((filter, index) => (
                    <div key={`user-search__filter-checkbox-wrap__${index}`} className="user-search__filter-checkbox-wrap">
                      <UserCustomCheckbox label={filter.label} value={filter.value} name="capability" checked={false} onInputChange={handleCheckboxInputChange}/>
                    </div>
                  ))}
                </div>
              </Accordion>
              <Accordion title="Country" icon="/img/icon-arrow-down.svg">
                <div className="user-search__filter-group__body">
                  <div className="user-search__filter-group__sub-title">Service Specification</div>
                  {countryFilters.map((filter, index) => (
                    <div key={`user-search__filter-checkbox-wrap__${index}`} className="user-search__filter-checkbox-wrap">
                      <UserCustomCheckbox label={filter.label} value={filter.value} name="country" checked={false} onInputChange={handleCheckboxInputChange}/>
                    </div>
                  ))}
                </div>
              </Accordion>
              <Accordion title="Opportunity" icon="/img/icon-arrow-down.svg">
                <div className="user-search__filter-group__body">
                  <div className="user-search__filter-group__sub-title">Opportunity Type</div>
                  {opportunityTypeFilters.map((filter, index) => (
                    <div key={`user-search__filter-checkbox-wrap__${index}`} className="user-search__filter-checkbox-wrap">
                      <UserCustomCheckbox label={filter.label} value={filter.value} name="opportunity_type" checked={false} onInputChange={handleCheckboxInputChange}/>
                    </div>
                  ))}
                </div>
              </Accordion>
              <Accordion title="Status" icon="/img/icon-arrow-down.svg">
                <div className="user-search__filter-group__body">
                  <div className="user-search__filter-group__sub-title">Status Type</div>
                  {statusFilters.map((filter, index) => (
                    <div key={`user-search__filter-checkbox-wrap__${index}`} className="user-search__filter-checkbox-wrap">
                      <UserCustomCheckbox label={filter.label} value={filter.value} name="status" checked={false} onInputChange={handleCheckboxInputChange}/>
                    </div>
                  ))}
                </div>
              </Accordion>
              <Accordion title="Service" icon="/img/icon-arrow-down.svg">
                <div className="user-search__filter-group__body">
                  <div className="user-search__filter-group__sub-title">Service Type</div>
                  {serviceFilters.map((filter, index) => (
                    <div key={`user-search__filter-checkbox-wrap__${index}`} className="user-search__filter-checkbox-wrap">
                      <UserCustomCheckbox label={filter.label} value={filter.value} name="service" checked={false} onInputChange={handleCheckboxInputChange}/>
                    </div>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
          <div className="user-search__main">
            <div className="user-search__search-bar">
              <div className="user-search__search-bar__country">
                <UserCustomInput type="select" />
              </div>
              <div className="user-search__search-bar__input">
                <UserCustomInput type="text" icon="/img/icon-search.svg" placeholder='Search here..' onInputChange={handleSearchInputChange}/>
              </div>
            </div>
            <div className="user-search__result">
              {
                filteredList.map((data, index) => (
                  <ContentItem key={index} data={data} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </UserPageTemplate>
  )
}

export default Search