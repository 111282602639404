import React, { useState } from 'react';

import BasicPageTemplate from '../templates/BasicPageTemplate';
import CustomInput from '../components/CustomInput';
import CustomButton from '../components/CustomButton';

const SetPassword = () => {

  const [submittedValue, setSubmittedValue] = useState('');

  const handleInputChange = (value) => {
    console.log('Current Input Value:', value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedValue(e.target.value);
  };

  return (
    <BasicPageTemplate>
      <div className="setpassword-form-wrap form-wrap align-center">
        <h1 className="form-title">Set Password</h1>
        <p className="form-desc">Please set your password</p>
        <form className="form-content" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-lock-password.svg"
                endIcon="/img/icon-eye-closed.svg"
                type="password"
                placeholder="Enter password"
                initialValue=""
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row mb-20">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-lock-password.svg"
                endIcon="/img/icon-eye-closed.svg"
                type="password"
                placeholder="Confirm Password"
                initialValue=""
                onInputChange={handleInputChange}
                endIconClickEvent={() => {}}
              />
            </div>
          </div>
          <div className="form-row">
            <CustomButton label="Contiue" />
          </div>
        </form>
      </div>
    </BasicPageTemplate>
  )
}

export default SetPassword