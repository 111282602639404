import { jwtDecode } from 'jwt-decode';

import { LS_KEY_AUTH_TOKEN } from '../constants/constants';

export const isLoggedIn = () => {

  const token = localStorage.getItem(LS_KEY_AUTH_TOKEN);
  
  if (!token) {
    return false;
  }
  
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    
    if (decoded.exp < currentTime) {
      return false;
    }
    
    return true;

  } catch (error) {
    return false;
  }
};