import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import BasicPageTemplate from '../templates/BasicPageTemplate';

const ImageLink = ({imgUrl = '', label = '', href = ''}) => {
  return (
    <Link className="image-link-wrap" to={ href }>
      <img className="image-link-wrap__img" src={ imgUrl } />
      <p className="image-link-wrap__label">{ label }</p>
    </Link>
  )
}

const LoginSignup = () => {

  return (
    <BasicPageTemplate>
      <div className="login-signup-links">
        <div className="image-links">
          <ImageLink imgUrl="/img/icon-login.svg" label="login" href="/login" />
          <ImageLink imgUrl="/img/icon-signup.svg" label="signup" href="/signup" />
        </div>
      </div>
    </BasicPageTemplate>
  )
}

export default LoginSignup