import React from 'react';
import { Link } from 'react-router-dom';

import SubPageNavbar from '../SubPageNavbar';

import './UserPageTemplate.css';

const UserPageTemplate = ({ children }) => {
  return (
    <div className="user-portal-container">
      <div className="user-portal__logo-link-container">
        <Link to="/" className="user-portal__logo-link">
          <img className="user-portal__logo-link__img" src="/img/logo.svg" />
        </Link>
      </div>
      <SubPageNavbar />
      <div className="user-portal-content">
        { children }
      </div>
    </div>
  );
};

export default UserPageTemplate;