import React from 'react';
import Select from 'react-select'

import useCustomInput from '../../hooks/useCustomInput';
import useCustomCheckbox from '../../hooks/useCustomCheckbox';

import './UserCustomInput.css';

export const UserCustomCheckbox = ({
  label = '',
  name = '',
  value = '',
  checked = false,
  onInputChange = null
}) => {
  const { checkedValue, bind } = useCustomCheckbox(checked);

  const handleChange = (e) => {
    bind.onChange();

    if (onInputChange) onInputChange(e.target.name, label, e.target.value, !checkedValue);
  }

  return (
    <div className="user-custom-input__checkbox-wrap">
      <label className="user-custom-input__checkbox-label">{label}
        <input
          type="checkbox"
          value={value}
          name={name}
          checked={bind.checked}
          onChange={handleChange}
        />
        <span className="user-custom-input__checkbox__checkmark" />
      </label>
    </div>
  )
}

const UserCustomInput = ({ 
  icon, 
  type = 'text', 
  label = '',
  name = '',
  initialValue = '', 
  placeholder = '', 
  options = [],
  rows = 4,
  cols = 50,
  preview = '',
  onInputChange, 
  endIcon = null, 
  endIconClickEvent = null, 
  ...rest }) => {

  const { value, inputProps, reset } = useCustomInput(initialValue, type);

  const handleChange = (e) => {
    inputProps.onChange(e);
    if (onInputChange) onInputChange(e.target.name, e.target.value);
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    if (onInputChange) {
      onInputChange(e.target.name + '_file', file);

      if (file) {
        onInputChange(e.target.name + '_preview', URL.createObjectURL(file));
      }
    }
  };

  const handleSelectChange = (name, value) => {
    if (onInputChange) onInputChange(name, value.value);
  };

  const handleMultiSelectChange = (name, values) => {
    if (onInputChange) onInputChange(name, values.map((value) => value.value).join(','));
  }

  return (
    <div className="user-custom-input-container">
      {
        label != '' && <div className="user-custom-input-label">{ label }</div>
      }
      <div className="user-custom-input-wrap">
        { icon && <img src={icon} alt="icon" className="custom-input-icon" /> }
        {type === 'textarea' ? (
          <textarea
            {...inputProps}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            onChange={handleChange}
            name={name}
            {...rest}
          />
        ) : type === 'select' ? (
          <Select 
            classNamePrefix="user-custom-react-select"
            options={options} 
            isMulti={false} 
            placeholder={placeholder}
            onChange={(value) => handleSelectChange(name, value)}
            {...rest}></Select>
        ) : type === 'multi-select' ? (
          <Select 
            classNamePrefix="user-custom-react-select"
            options={options} 
            isMulti={true}
            placeholder={placeholder}
            onChange={(values) => handleMultiSelectChange(name, values)}
            {...rest}></Select>
        ) : type === 'file' ? (
          <div className="user-custom-">
            <div className="user-custom-img-preview">
              {
                preview && <img src={preview} alt="preview" />
              }
            </div>
            <input type="file" name={name} onChange={handleFileChange} {...rest}/>
          </div>
        ) : (
          <input 
            {...inputProps} 
            placeholder={placeholder} 
            onChange={handleChange} 
            name={name}
            {...rest} 
          />
        )}
        { endIcon && <img src={endIcon} alt="icon" className="custom-input-end-icon" onClick={endIconClickEvent} /> }
      </div>
    </div>
  );
};

export default UserCustomInput;