import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import API from '../utils/api';
import BasicPageTemplate from '../templates/BasicPageTemplate';
import CustomInput from '../components/CustomInput';
import CustomButton from '../components/CustomButton';
import { LS_KEY_AUTH_TOKEN, LS_KEY_AUTH_USER } from '../constants/constants';
import { clearCache } from '../utils/global';

const Login = () => {

  const { loginWithPopup, getAccessTokenSilently } = useAuth0();

  const [formData, setFormData] = useState({ 
    'email': '',
    'password': ''
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      clearCache();
      
      await loginWithPopup({
        authorizationParams: {
          prompt: "login"
        }
      });
      
      const token = await getAccessTokenSilently({
        ignoreCache: true
      });

      let loginData = formData;
      loginData['token'] = token;

      const response = await API.post('/api/users/login', loginData);

      if(response['success']) {
        localStorage.setItem(LS_KEY_AUTH_TOKEN, response['token']);
        localStorage.setItem(LS_KEY_AUTH_USER, JSON.stringify(response['user']));

        window.location.href = "/dashboard";
      } else {
        alert(response['message']);
      }

    } catch(error) {
      setResponseMessage('error!');
    }
  };

  return (
    <BasicPageTemplate>
      <div className="login-form-wrap form-wrap align-center">
        <h1 className="form-title">Welcome back</h1>
        <p className="form-desc">Welcome back! Please enter your details.</p>
        <form className="form-content" onSubmit={handleSubmit}>
          {
            responseMessage != '' && (
              <div className="response-message">{ responseMessage }</div>
            )
          }
          <div className="form-row">
            <CustomButton label="Login" />
          </div>
        </form>
      </div>
    </BasicPageTemplate>
  )
}

export default Login