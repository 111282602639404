import React from 'react';
import Select from 'react-select'

import useCustomInput from '../hooks/useCustomInput';
import './CustomInput.css';

const CustomInput = ({ 
  icon, 
  type = 'text', 
  label = '',
  name = '',
  checked = false,
  initialValue = '', 
  placeholder = '', 
  options = [],
  rows = 4,
  cols = 50,
  onInputChange, 
  endIcon = null, 
  endIconClickEvent = null, 
  ...rest }) => {

  const { value, inputProps, reset } = useCustomInput(initialValue, type);

  const handleChange = (e) => {
    inputProps.onChange(e);
    if (onInputChange) onInputChange(e.target.name, e.target.value);
  };

  const handleSelectChange = (name, value) => {
    if (onInputChange) onInputChange(name, value.value);
  };

  const handleMultiSelectChange = (name, values) => {
    if (onInputChange) onInputChange(name, values.map((value) => value.value).join(','));
  }

  return (
    <div className="custom-input-container">
      { icon && <img src={icon} alt="icon" className="custom-input-icon" /> }
      {type === 'textarea' ? (
        <textarea
          {...inputProps}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          onChange={handleChange}
          name={name}
          {...rest}
        />
      ) : type === 'select' ? (
        <Select 
          classNamePrefix="custom-react-select"
          options={options} 
          isMulti={false} 
          placeholder={placeholder}
          onChange={(value) => handleSelectChange(name, value)}
          {...rest}></Select>
      ) : type === 'multi-select' ? (
        <Select 
          classNamePrefix="custom-react-select"
          options={options} 
          isMulti={true}
          placeholder={placeholder}
          onChange={(values) => handleMultiSelectChange(name, values)}
          {...rest}></Select>
      ) : (
        <input 
          {...inputProps} 
          placeholder={placeholder}
          onChange={handleChange}
          name={name}
          {...rest} 
        />
      )}
      { endIcon && <img src={endIcon} alt="icon" className="custom-input-end-icon" style={{ cursor: type == 'password' || type == 'text' ? 'pointer' : '' }} onClick={endIconClickEvent} /> }
    </div>
  );
};

export default CustomInput;