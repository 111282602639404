import React from 'react';
import './CustomButton.css';

const CustomButton = ({ label = 'Submit', type = "submit", onClick, disabled = false, isFullWidth = true, edgeColor = 'var(--color-gray)', ...rest }) => {
  const edgeStyle = {
    borderTop: `5px solid ${edgeColor}`,
    borderRight: `5px solid ${edgeColor}`
  }

  return (
    <div>
      <button
        type={type}
        className={`custom-button ${disabled ? 'custom-button-disabled' : ''} ${isFullWidth ? 'custom-button--w100' : ''}`}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {label}
      </button>
    </div>
  );
};

export default CustomButton;