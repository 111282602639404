import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

import API from '../utils/api';
import { companyTypes, interestedTechnologies, subscriptionTypes, countries, billingCycles } from '../constants/constants';
import BasicPageTemplate from '../templates/BasicPageTemplate';
import { PlanContext } from '../context/PlanContext';
import CustomInput from '../components/CustomInput';
import CustomButton from '../components/CustomButton';
import { getSubscriptionsFromType, getPlanFromId } from '../utils/global';

const Signup = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company_size = queryParams.get('size');
  const planId = queryParams.get('plan');
  const period = queryParams.get('period');

  const { loginWithPopup, getAccessTokenSilently } = useAuth0();
  const plans = useContext(PlanContext);

  const [formData, setFormData] = useState({
    'company_size': company_size,
    'subscription': planId,
    'full_name': '',
    'company_name': '',
    'position': '',
    'email': '',
    'address': '',
    'phone': '',
    'company_country': '',
    'technology_area': '',
    'billing_cycle': '',
    'company_type': ''
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);

  const handleInputChange = (name, value) => {
    let updatedData = {...formData, [name]: value};

    if (name == 'company_size') {
      updatedData['subscription'] = '';
    }

    setFormData(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await loginWithPopup();
      const token = await getAccessTokenSilently({
        authorizationParams: {
          prompt: "login"
        }
      });

      let signupData = formData;
      signupData['token'] = token;
      
      const response = await API.post('/api/users/signup', signupData);

      if (response['success']) {
        window.location.href = `/checkout?plan=${formData['subscription']}&period=${period}`;

      } else {
        setResponseMessage(response['message']);
      }

    } catch(error) {
      setResponseMessage('Failed!');
    }
  };

  useEffect(() => {
    if (formData['company_size'] != '') {
      setSubscriptions(getSubscriptionsFromType(plans, formData['company_size']));
    }
  }, [formData])

  return (
    <BasicPageTemplate>
      <div className="signup-form-wrap form-wrap align-center">
        <h1 className="form-title">Create an account</h1>
        <p className="form-desc">Please enter your details to create an account</p>
        <form className="form-content" onSubmit={handleSubmit}>
          <div className="form-row-cols-2">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-case.svg"
                type="select"
                options={subscriptionTypes}
                placeholder="Company Size"
                name="company_size"
                onInputChange={handleInputChange}
              />
            </div>
            <div className="form-col">
              <CustomInput
                icon="/img/icon-case.svg"
                type="select"
                options={subscriptions}
                placeholder="Subscription"
                name="subscription"
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row-cols-2">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-user-rounded.svg"
                type="text"
                placeholder="Full name"
                initialValue=""
                name="full_name"
                required={false}
                onInputChange={handleInputChange}
              />
            </div>
            <div className="form-col">
              <CustomInput
                icon="/img/icon-case.svg"
                type="text"
                placeholder="Company name"
                initialValue=""
                name="company_name"
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-case.svg"
                type="text"
                placeholder="Position at company"
                initialValue=""
                name="position"
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-map-point.svg"
                type="text"
                placeholder="Address"
                initialValue=""
                name="address"
                onInputChange={handleInputChange}
                endIconClickEvent={() => {}}
              />
            </div>
          </div>
          <div className="form-row-cols-2">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-phone-calling-rounded.svg"
                type="text"
                placeholder="Phone number"
                initialValue=""
                name="phone"
                onInputChange={handleInputChange}
              />
            </div>
            <div className="form-col">
              <CustomInput
                icon="/img/icon-global.svg"
                type="select"
                options={countries}
                placeholder="Company home location country"
                name="company_country"
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-network.svg"
                type="multi-select"
                placeholder="Technology area of interest"
                options={interestedTechnologies}
                name="technology_area"
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row-cols-2">
            <div className="form-col">
              <CustomInput
                icon="/img/icon-case.svg"
                type="select"
                options={billingCycles}
                placeholder="Billing Cycle"
                initialValue=""
                name="billing_cycle"
                onInputChange={handleInputChange}
              />
            </div>
            <div className="form-col">
              <CustomInput
                icon="/img/icon-case.svg"
                type="select"
                options={companyTypes}
                placeholder="Company type"
                initialValue=""
                name="company_type"
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          {
            responseMessage != '' && (
              <div className="response-message">{ responseMessage }</div>
            )
          }
          <div className="form-row">
            <CustomButton label="Sign up" />
          </div>
        </form>
      </div>
    </BasicPageTemplate>
  )
}

export default Signup