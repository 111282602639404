import React, { useState, useEffect } from 'react'

import API from '../../utils/api';
import UserPageTemplate from './templates/UserPageTemplate';
import ContentItem from '../../components/user/ContentItem';
import { teammates, TeammateWrap } from './TeammateMarketPlace';

import './Dashboard.css';

const NAV_OPPORTUNITIES = 'navOpportunities';
const NAV_TEAMMATES = 'navTeamMates';

const navLinks = [
  {
    'iconUrl': '/img/icon-widget-blue.svg',
    'label': 'My Saved Opportunities',
    'id': NAV_OPPORTUNITIES,
    'active': true,
  },
  {
    'iconUrl': '/img/icon-widget-blue.svg',
    'label': 'My Teammates',
    'id': NAV_TEAMMATES,
    'active': false,
  }
];


const SOLICITATION_OPEN = 'solicitationOpen';
const SELECTED_SOLICITATION_CLOSED = 'solicitationClosed';

const DashboardNavLink = ({navLink, onClick}) => {
  return (
    <div className={navLink.active ? "dashboard-nav-link active" : "dashboard-nav-link"} onClick={() => onClick(navLink.id)}>
      <img className="dashboard-nav-link__img" src={navLink.iconUrl} />
      <p className="dashboard-nav-link__text">{ navLink.label }</p>
    </div>
  )
}

const Dashboard = () => {

  const [openSolicitations, setOpenSolicitations] = useState([]);
  const [closedSolicitations, setClosedSolicitations] = useState([]);
  const [visibleSolicitations, setVisibleSolicitations] = useState([]);
  const [selectedSolicitation, setSelectedSolicitation] = useState('')

  const [navs, setNavs] = useState(navLinks);
  const [selectedNav, setSelectedNav] = useState(NAV_OPPORTUNITIES);
  
  const [loadingOpportunities, setLoadingOpportunities] = useState(false);

  useEffect(() => {

    async function fetchOpportunities() {
      setLoadingOpportunities(true);

      try {
        
        const response = await API.get('/api/protected/solicitations/saved');
  
        if (response.success) {
          setOpenSolicitations(response.open);
          setClosedSolicitations(response.closed);

          setSelectedSolicitation(SOLICITATION_OPEN);
        }
  
      } catch(error) {
        console.log('error:', error);
      }

      setLoadingOpportunities(false);
    }
    
    async function fetchTeamMates() {
      try {
        const response = await API.get('/api/protected/solicitations/saved');
  
        if (response.success) {
          setOpenSolicitations(response.open);
          setClosedSolicitations(response.closed);

          setSelectedSolicitation(SOLICITATION_OPEN);
        }
  
      } catch(error) {
        console.log('error:', error);
      }
    }

    if (selectedNav == NAV_OPPORTUNITIES) {
      fetchOpportunities();

    } else if (selectedNav == NAV_TEAMMATES) {
      fetchTeamMates();
    }

  }, [selectedNav]);
  
  useEffect(() => {
    if (selectedSolicitation == SOLICITATION_OPEN) {
      setVisibleSolicitations(openSolicitations);

    } else if (selectedSolicitation == SELECTED_SOLICITATION_CLOSED) {
      setVisibleSolicitations(closedSolicitations);
    }

  }, [selectedSolicitation])

  const changeNav = (navId) => {
    setSelectedNav(navId);
    setNavs((prevNavs) => prevNavs.map((nav) => ({...nav, active: nav.id == navId})))
  }

  return (
    <UserPageTemplate>
      <div className="user-dashboard-content">
        <div className="user-dashboard__navbar">
          {
            navs.map((navLink, index) => (
              <DashboardNavLink key={'dashboard-nav-link__' + index} navLink={navLink} onClick={(navId) => changeNav(navId)}/>
            ))
          }
        </div>
        <div className="user-dashboard__body">
          <div className="user-dashboard__side-left">
            <div className="user-dashboard__summary">
              <div className="user-dashboard__summary__title">Summary</div>
              <div className="user-dashboard__summary__filters">
                <div className={selectedSolicitation == SOLICITATION_OPEN ? "user-dashboard__summary__filter active" : "user-dashboard__summary__filter"} onClick={() => setSelectedSolicitation(SOLICITATION_OPEN)}>Saved Current Opportunities ({openSolicitations.length})</div>
                <div className={selectedSolicitation == SELECTED_SOLICITATION_CLOSED ? "user-dashboard__summary__filter active" : "user-dashboard__summary__filter"} onClick={() => setSelectedSolicitation(SELECTED_SOLICITATION_CLOSED)}>Saved Closed Opportunities ({closedSolicitations.length})</div>
              </div>
            </div>
          </div>
          <div className="user-dashboard__center">
            {
              selectedNav == NAV_OPPORTUNITIES && (
                <>
                {
                  loadingOpportunities && (<>Loading...</>)
                }
                {
                  !loadingOpportunities && (
                    <div className="user-dashboard__center-list">
                      {
                        visibleSolicitations.map((solicitation, index) => <ContentItem key={index} data={solicitation}/>)
                      }
                    </div>
                  )
                }
                </>
              )
            }
            
            {
              selectedNav == NAV_TEAMMATES && (
                <div className="my-teammate-grid">
                {
                  teammates.map((data, index) => <TeammateWrap key={index} data={data} />)
                }
                </div>
              )
            }
          </div>
          <div className="user-dashboard__side-right">
            <div className="user-dashboard__event">
              Event B
            </div>
          </div>
        </div>
      </div>
    </UserPageTemplate>
  )
}

export default Dashboard