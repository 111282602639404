import React from 'react';

import './HeadSection.css';

const HeadSection = ({ title }) => {
  return (
    <section className="head-section">
      <h1 className="head-section__title">{ title }</h1>
    </section>
  );
};

export default HeadSection;