import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useAuth0 } from "@auth0/auth0-react";

import { isLoggedIn } from '../utils/auth';
import { LS_KEY_AUTH_TOKEN, LS_KEY_AUTH_USER } from '../constants/constants';
import API from '../utils/api';

import './MainHeader.css';

const MainHeader = () => {

  const { logout } = useAuth0();

  const handleLogout = async () => {
    
    logout({
      logoutParams: {
        returnTo: window.location.origin, // Redirect back to home
      },
      federated: true, // Ensures logout from Google/Microsoft too
    });

    try {
      const response = await API.post('/api/users/logout');

      if(response['success']) {

        localStorage.removeItem(LS_KEY_AUTH_TOKEN);
        localStorage.removeItem(LS_KEY_AUTH_USER);

        window.location.href = "/login";
      } else {
        alert(response['message']);
      }

    } catch(error) {
      
    }
  }

  const user = JSON.parse(localStorage.getItem(LS_KEY_AUTH_USER));

  return (
    <header>
      <div className="header-container">
        <div className="container">
          <div className="header-wrap">
            <RouterLink className="main-logo-link" to="/">
              <img className="main-header-logo-img" src="/img/logo-white.svg" alt="logo image" />
            </RouterLink>
            <div className="header-main-links">
              <RouterLink className="header-main-link" to="/">home</RouterLink>
              <ScrollLink className="header-main-link" to="about" smooth={true}>about</ScrollLink>
              <ScrollLink className="header-main-link" to="services" smooth={true}>services</ScrollLink>
              <ScrollLink className="header-main-link" to="contact" smooth={true}>contact us</ScrollLink>
            </div>
            {
              isLoggedIn()
              ? (
                <div className="header-right-links">
                  <RouterLink className="header-right-link" to="/dashboard">{user.full_name}</RouterLink>
                  <span className="header-right-link" onClick={handleLogout}>Logout</span>
                </div>
              )
              : (
                <div className="header-right-links">
                  <RouterLink className="header-right-link" to="/login">login</RouterLink>
                  <ScrollLink className="header-main-link" to="pricing" smooth={true}>sign up</ScrollLink>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;