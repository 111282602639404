import React from 'react';

import LabelSection from './LabelSection';

import './AboutSection.css';

const AboutSection = () => {
  return (
    <section className="about-section">
      <img className="about-section__target-img" src="/img/target.svg" alt="target image" />
      <img className="about-section__plane-img" src="/img/plane.svg" alt="plane image" />
      <LabelSection labelFront="who we are" labelBehind="about us"/>
      <div className="container">
        <div className="about-section__img-desc-wrap">
          <img className="" src="/img/plane-inside.png" />
          <div className="about-section__desc">
            <p>Deftechlink was created by a team of military and technology industry experts who recognized the need for a more efficient way to discover global procurement and research & development opportunities. Traditional methods, relying heavily on personal networks, were time-consuming and often left key opportunities hidden in obscure corners of LinkedIn or buried in complex government procurement systems.</p>
            <p>We built Deftechlink to cut through the clutter. Our platform gives you direct access to global defense opportunities, from procurement to R&D, all in one place. With the right information and key team members at your side, we equip you to not only find opportunities but win them.</p>
            <p>In an increasingly interconnected world, Deftechlink is your bridge to the future of defense technology.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;