import React, { useContext, useState } from 'react';

import { PlanContext } from '../context/PlanContext';
import PlanCard from '../components/PlanCard';
import PlanPeriodSwitcher from '../components/PlanPeriodSwitcher';
import { PLAN_PERIOD_MONTHLY, currency } from '../constants/constants';

import './PlanSection.css';

const PlanSection = () => {
  
  const plans = useContext(PlanContext);
  const [period, setPeriod] = useState(PLAN_PERIOD_MONTHLY)
  
  const changePlanPeriod = (period) => {
    setPeriod(period)
  }

  return (
    <section className="plan-section">
      <div className="plan-card-groups">
        <div className="plan-period-container">
          <PlanPeriodSwitcher onChangePeriod={changePlanPeriod}/>
        </div>
        <div className="plan-card-list">
          {plans && plans['dtl'].map((plan, index) => (
            <PlanCard key={index} size="dtl" plan={plan} currency={currency} period={period} />
          ))}
        </div>
        <div className="plan-card-group">
          <h2 className="page-sub-title">teammate marketplace</h2>
          <div className="plan-card-list">
            {plans && plans['marketplace'].map((plan, index) => (
              <PlanCard key={index} size="marketplace" plan={plan} currency={currency} period={period} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanSection;