import React from 'react';

import './UserCustomButton.css';

export const USER_CUSTOM_BUTTON_TYPE_FILL = 'user_custom_button_type_fill';
export const USER_CUSTOM_BUTTON_TYPE_OUTLINE = 'user_custom_button_type_outline';

const UserCustomButton = ({ label = '', iconImg, buttonType = USER_CUSTOM_BUTTON_TYPE_FILL, onClick, disabled = false, isFullWidth = false, ...rest }) => {
  
  return (
    <div className="user-custom-button-wrap">
      <button
        className={`user-custom-button ${disabled ? 'user-custom-button-disabled' : ''} ${isFullWidth ? 'custom-button--w100' : ''} ${buttonType}`}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        { iconImg && <img src={iconImg} alt="icon" className="user-custom-input-icon" /> }
        {label}
      </button>
    </div>
  );
};

export default UserCustomButton;