import React from 'react';

import './ContentItem.css';

const ContentItem = ({ data }) => {
  
  return (
    <div className="content-item-wrap">
      <div className="content-item-grid">
        <div className="content-item-col">
          <div className="content-item__cell">
            <div className="content-item__cell-label">Title:</div>
            <div className="content-item__cell-value">{data.title}</div>
          </div>
          <div className="content-item__cell">
            <div className="content-item__cell-label">Capability:</div>
            <div className="content-item__cell-value">{data.capability}</div>
          </div>
          <div className="content-item__cell">
            <div className="content-item__cell-label">Customer:</div>
            <div className="content-item__cell-value">{data.customer}</div>
          </div>
          <div className="content-item__cell">
            <div className="content-item__cell-label">Due date:</div>
            <div className="content-item__cell-value">{data.due_date}</div>
          </div>
        </div>
        <div className="content-item-col">
          <div className="content-item__cell">
            <div className="content-item__cell-label">Reference#:</div>
            <div className="content-item__cell-value">{data.ref_id_number}</div>
          </div>
          <div className="content-item__cell">
            <div className="content-item__cell-label">Funding:</div>
            <div className="content-item__cell-value">{data.funding}</div>
          </div>
          <div className="content-item__cell">
            <div className="content-item__cell-label">Eligibility:</div>
            <div className="content-item__cell-value">{data.eligibility}</div>
          </div>
          <div className="content-item__cell">
            <div className="content-item__cell-label">Submission format:</div>
            <div className="content-item__cell-value">{data.submission_format}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentItem;