import axios from 'axios';

import { LS_KEY_AUTH_TOKEN } from '../constants/constants';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

API.interceptors.request.use(
  (config) => {
    
    const protectedRoutes = ['/protected'];
    const isProtectedRoute = protectedRoutes.some(route => config.url.includes(route));

    const token = localStorage.getItem(LS_KEY_AUTH_TOKEN);

    if (isProtectedRoute && token) {
      config.headers['x-auth-token'] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    // Handle successful responses here (e.g., check for custom response status)
    return response.data;
  },
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default API;