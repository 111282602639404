import React from 'react'
import { useNavigate } from 'react-router-dom';

import UserPageTemplate from './templates/UserPageTemplate';
import UserCustomInput from '../../components/user/UserCustomInput';

import './TeammateMarketPlace.css';

export const teammates = [
  {
    id: 1,
    logo: '/img/logo1.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  },
  {
    id: 2,
    logo: '/img/logo2.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  },
  {
    id: 3,
    logo: '/img/logo1.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  },
  {
    id: 4,
    logo: '/img/logo2.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  },
  {
    id: 5,
    logo: '/img/logo1.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  },
  {
    id: 6,
    logo: '/img/logo2.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  },
  {
    id: 7,
    logo: '/img/logo1.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  },
  {
    id: 8,
    logo: '/img/logo2.png',
    company_name: 'Alpha Tech',
    teammate_type: 'Lorem',
    state_country: 'New York, USA',
    service: 'Lorem'
  }
]

export const TeammateWrap = ({data}) => {
  const navigate = useNavigate();

  const goToDetails = () => {
    navigate('/teammate-marketplace/' + data.id);  
  }

  return (
    <div className="teammate-wrap" onClick={goToDetails}>
      <img className="teammate-logo" src={data.logo} />
      <div className="teammate-info-row">
        <b>Company name:</b>
        <span>{data.company_name}</span>
      </div>
      <div className="teammate-info-row">
        <b>Teammate Type:</b>
        <span>{data.teammate_type}</span>
      </div>
      <div className="teammate-info-row">
        <b>State/Country:</b>
        <span>{data.state_country}</span>
      </div>
      <div className="teammate-info-row">
        <b>Service Specialization:</b>
        <span>{data.service}</span>
      </div>
    </div>
  )
}

const TeammateMarketPlace = () => {

  const handleSearchInputChange = (name, value) => {
    // const timeoutId = setTimeout(() => {
    //   setFilteredList(staticData.filter((data) => {
    //     return (data.title.includes(value) || data.capability.includes(value) || data.eligibility.includes(value) || data.submission_format.includes(data));
    //   }));
    // }, 500);

    // return () => clearTimeout(timeoutId);
  }

  return (
    <UserPageTemplate>
      <div className="user-dashboard-content">
        <div className="teammate-search-wrap">
          <div className="user-search__search-bar">
            <div className="user-search__search-bar__country">
              <UserCustomInput type="select" />
            </div>
            <div className="user-search__search-bar__input">
              <UserCustomInput type="text" icon="/img/icon-search.svg" placeholder='Search here..' onInputChange={handleSearchInputChange}/>
            </div>
          </div>
        </div>
        <div className="teammate-grid">
          {
            teammates.map((data, index) => <TeammateWrap key={index} data={data} />)
          }
        </div>
      </div>
    </UserPageTemplate>
  )
}

export default TeammateMarketPlace