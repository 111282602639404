import React, { useState } from 'react';

import { PLAN_PERIOD_MONTHLY, PLAN_PERIOD_YEARLY } from '../constants/constants';

import './PlanPeriodSwitcher.css';

const PlanPeriodSwitcher = ({ onChangePeriod }) => {
  const [period, setPeriod] = useState(PLAN_PERIOD_MONTHLY);

  const changePeriod = () => {
    const newPeriod = period == PLAN_PERIOD_MONTHLY ? PLAN_PERIOD_YEARLY : PLAN_PERIOD_MONTHLY;
    setPeriod(newPeriod);
    onChangePeriod(newPeriod);
  }

  return (
    <div className="plan-period-wrap">
      <span className={`plan-period-name ${period == PLAN_PERIOD_MONTHLY ? 'plan-period-name--active' : ''}`}>monthly</span>
      <div className="plan-period__radio" onClick={changePeriod}>
        <span className={`plan-period__radio-dot ${period == PLAN_PERIOD_MONTHLY ? '' : 'plan-period__radio-dot--right'}`}></span>
      </div>
      <span className={`plan-period-name ${period == PLAN_PERIOD_YEARLY ? 'plan-period-name--active' : ''}`}>yearly</span>
      <span className="plan-period-save" style={{visibility: period == PLAN_PERIOD_YEARLY ? 'visible' : 'hidden'}}>20% Save</span>
    </div>
  );
};

export default PlanPeriodSwitcher;