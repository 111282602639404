import React from 'react'
import { useParams } from 'react-router-dom';

import UserPageTemplate from './templates/UserPageTemplate';
import UserCustomButton, { USER_CUSTOM_BUTTON_TYPE_OUTLINE } from '../../components/user/UserCustomButton';
import UserCustomInput from '../../components/user/UserCustomInput';

import './TeammateDetails.css';

const TeammateDetails = () => {

  const { id } = useParams();

  console.log('details:', id);

  return (
    <UserPageTemplate>
      <div className="user-dashboard-content">
        <div className="user-partner-marketplace__body">
          <div className="user-partner-marketplace__head" style={{backgroundImage: 'url("/img/partner-bg.png")'}}>
            <img className="user-partner-marketplace__logo-img" src="/img/partner-logo.png" />
            <img className="user-partner-marketplace__profile-img" src="/img/profile.png" />
          </div>
          <div className="user-partner-marketplace__contact-btn-wrap">
            <UserCustomButton label="Contact Teammate" />
          </div>
          <div className="user-partner-marketplace__search-bar">
            <div className="user-search__search-bar__country">
              <UserCustomInput type="select" />
            </div>
            <div className="user-search__search-bar__input">
              <UserCustomInput type="text" icon="/img/icon-search.svg" placeholder='Search here..' />
            </div>
          </div>
          <h1 className="user-partner-marketplace-title">Parnter Marketplace</h1>
          <div className="user-partner-marketplace__infos">
            <div className="user-partner-marketplace__info-col">
              <p className="user-partner-marketplace__info-label">Company name</p>
              <p className="user-partner-marketplace__info-value">Alpha Tech</p>
            </div>
            <div className="user-partner-marketplace__info-col">
              <p className="user-partner-marketplace__info-label">Teammate Type</p>
              <p className="user-partner-marketplace__info-value">Lorem</p>
            </div>
            <div className="user-partner-marketplace__info-col">
              <p className="user-partner-marketplace__info-label">Capability Focus Area</p>
              <p className="user-partner-marketplace__info-value">Lorem</p>
            </div>
            <div className="user-partner-marketplace__info-col">
              <p className="user-partner-marketplace__info-label">State/Country</p>
              <p className="user-partner-marketplace__info-value">New York, USA</p>
            </div>
            <div className="user-partner-marketplace__info-col">
              <p className="user-partner-marketplace__info-label">Service Specialization</p>
              <p className="user-partner-marketplace__info-value">Lorem</p>
            </div>
          </div>
          <div className="user-parnter-marketplace-block">
            <h2 className="user-partner-marketplace-sub-title">Offering</h2>
          </div>
          <div className="user-parnter-marketplace-block">
            <h2 className="user-partner-marketplace-sub-title">Payment Structure</h2>
            <div className="user-partner-marketplace-payment-btns">
              <UserCustomButton label="Commission" buttonType={USER_CUSTOM_BUTTON_TYPE_OUTLINE} />
              <UserCustomButton label="Retainer" buttonType={USER_CUSTOM_BUTTON_TYPE_OUTLINE} />
              <UserCustomButton label="Hourly" buttonType={USER_CUSTOM_BUTTON_TYPE_OUTLINE} />
            </div>
          </div>
          <div className="user-parnter-marketplace-block">
            <h2 className="user-partner-marketplace-sub-title">Previous wins</h2>
          </div>
        </div>
      </div>
    </UserPageTemplate>
  )
}

export default TeammateDetails