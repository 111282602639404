import React from 'react';

import MainHeader from './MainHeader';
import MainFooter from './MainFooter';

import './MainPageTemplate.css';

const MainPageTemplate = ({ children }) => {
  return (
    <div className="main-page-container">
      <MainHeader />
      <div className="main-page-content">
        { children }
      </div>
      <MainFooter />
    </div>
  );
};

export default MainPageTemplate;