import { useState } from 'react';

const useCustomInput = (initialValue = '', type = 'text') => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  const validate = (value) => {
    if (type === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      setError('Invalid email address');
    } else {
      setError('');
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    validate(e.target.value);
  };

  const reset = () => {
    setValue(initialValue);
    setError('');
  };

  return { value, setValue, reset, error, inputProps: { type, value, onChange: handleChange } };
};

export default useCustomInput;