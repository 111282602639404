import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";

import MainPageTemplate from '../templates/MainPageTemplate';
import HeadSection from '../sections/HeadSection';
import CheckoutSection from '../sections/CheckoutSection';
import { PlanContext } from '../context/PlanContext';
import API from '../utils/api';
import { getPlanFromId } from '../utils/global';
import { PLAN_PERIOD_MONTHLY } from '../constants/constants';

const Checkout = () => {

  const plans = useContext(PlanContext);

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('plan');
  const period = queryParams.get('period');
  
  const plan = getPlanFromId(plans, planId);

  useEffect(() => {

    async function fecthConfig() {
      try {
        const response = await API.post('/api/payment/stripe/config');
  
        setStripePromise(loadStripe(response.publishableKey));
  
      } catch(error) {
        console.log('error:', error);
      }
    }
    
    fecthConfig();
  }, []);

  useEffect(() => {

    async function createPaymentIntent() {
      try {
        const response = await API.post('/api/payment/stripe/create-payment-intent', {product_id: period == PLAN_PERIOD_MONTHLY ? plan.monthly_product_id : plan.annually_product_id});
  
        setClientSecret(response.clientSecret);
        setPaymentIntentId(response.paymentIntentId);
  
      } catch(error) {
        console.log('error:', error);
      }
    }
    
    if (plan) {
      createPaymentIntent();
    }
    
  }, [plan]);

  return (
    <>
      {clientSecret && paymentIntentId && stripePromise && (
        <Elements stripe={stripePromise} options={{clientSecret, paymentIntentId}}>
          <MainPageTemplate>
            <HeadSection title="checkout"/>
            <CheckoutSection plan={plan} paymentIntentId={paymentIntentId}/>
          </MainPageTemplate>
        </Elements>
      )}
    </>
  )
}

export default Checkout