import React from 'react';

import './ArtifactItem.css';

const ArtifactItem = ({ data, featureImgUrl }) => {
  
  return (
    <div className="video-item-wrap">
      <div className="video-item__feature" style={{backgroundImage: `url(${featureImgUrl})`}}>
        <img className="video-item__play-btn-img" src="/img/icon-video-play.svg" />
      </div>
      <p className="video-item__label">{data.title}</p>
    </div>
  );
};

export default ArtifactItem;