import React from 'react'
import { Link, useLocation } from 'react-router-dom';

import './SubPageNavbar.css';

const DashboardIcon = ({ active = false }) => {
  const stroke = active ? "#ffffff" : "#00011D";

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.6875 6.1875C2.6875 4.2545 4.2545 2.6875 6.1875 2.6875C8.1205 2.6875 9.6875 4.2545 9.6875 6.1875C9.6875 8.1205 8.1205 9.6875 6.1875 9.6875C4.2545 9.6875 2.6875 8.1205 2.6875 6.1875Z" stroke={stroke} strokeWidth="1.5"/>
      <path d="M12.3125 15.8125C12.3125 13.8795 13.8795 12.3125 15.8125 12.3125C17.7455 12.3125 19.3125 13.8795 19.3125 15.8125C19.3125 17.7455 17.7455 19.3125 15.8125 19.3125C13.8795 19.3125 12.3125 17.7455 12.3125 15.8125Z" stroke={stroke} strokeWidth="1.5"/>
      <path d="M19.3125 6.1875C19.3125 4.53758 19.3125 3.71263 18.7999 3.20006C18.2874 2.6875 17.4624 2.6875 15.8125 2.6875C14.1626 2.6875 13.3376 2.6875 12.8251 3.20006C12.3125 3.71263 12.3125 4.53758 12.3125 6.1875C12.3125 7.83742 12.3125 8.66237 12.8251 9.17494C13.3376 9.6875 14.1626 9.6875 15.8125 9.6875C17.4624 9.6875 18.2874 9.6875 18.7999 9.17494C19.3125 8.66237 19.3125 7.83742 19.3125 6.1875Z" stroke={stroke} strokeWidth="1.5"/>
      <path d="M9.6875 15.8125C9.6875 14.1626 9.6875 13.3376 9.17494 12.8251C8.66237 12.3125 7.83742 12.3125 6.1875 12.3125C4.53758 12.3125 3.71263 12.3125 3.20006 12.8251C2.6875 13.3376 2.6875 14.1626 2.6875 15.8125C2.6875 17.4624 2.6875 18.2874 3.20006 18.7999C3.71263 19.3125 4.53758 19.3125 6.1875 19.3125C7.83742 19.3125 8.66237 19.3125 9.17494 18.7999C9.6875 18.2874 9.6875 17.4624 9.6875 15.8125Z" stroke={stroke} strokeWidth="1.5"/>
    </svg>
  )
}

const SearchIcon = ({active}) => {
  const stroke = active ? "#ffffff" : "#00011D";

  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0625" cy="10.5625" r="8.3125" stroke={stroke} strokeWidth="1.5"/>
      <path d="M16.1875 16.6875L19.25 19.75" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

const PartnerIcon = ({active}) => {
  const stroke = active ? "#ffffff" : "#00011D";

  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.875" cy="5.75" r="3.5" stroke={stroke} strokeWidth="1.5"/>
      <path d="M13.125 8.375C14.5747 8.375 15.75 7.19975 15.75 5.75C15.75 4.30025 14.5747 3.125 13.125 3.125" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
      <ellipse cx="7.875" cy="15.375" rx="6.125" ry="3.5" stroke={stroke} strokeWidth="1.5"/>
      <path d="M15.75 12.75C17.285 13.0866 18.375 13.9391 18.375 14.9375C18.375 15.8382 17.488 16.62 16.1875 17.0116" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

const LearnIcon = ({active}) => {
  const stroke = active ? "#ffffff" : "#00011D";

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 7.5C4 5.02513 4 3.78769 4.76884 3.01884C5.53769 2.25 6.77513 2.25 9.25 2.25H12.75C15.2249 2.25 16.4623 2.25 17.2312 3.01884C18 3.78769 18 5.02513 18 7.5V14.5C18 16.9749 18 18.2123 17.2312 18.9812C16.4623 19.75 15.2249 19.75 12.75 19.75H9.25C6.77513 19.75 5.53769 19.75 4.76884 18.9812C4 18.2123 4 16.9749 4 14.5V7.5Z" stroke={stroke} strokeWidth="1.5"/>
      <path d="M17.9106 14.5H7.41056C6.59683 14.5 6.18997 14.5 5.85616 14.5894C4.95029 14.8322 4.24273 15.5397 4 16.4456" stroke={stroke} strokeWidth="1.5"/>
      <path d="M7.5 6.625H14.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M7.5 9.6875H11.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

const AccountIcon = ({active}) => {
  const stroke = active ? "#ffffff" : "#00011D";

  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="8.375" r="2.625" stroke={stroke} strokeWidth="1.5"/>
      <circle cx="10.5" cy="11" r="8.75" stroke={stroke} strokeWidth="1.5"/>
      <path d="M15.723 18C15.5838 15.4699 14.8091 13.625 10.5 13.625C6.19084 13.625 5.4162 15.4699 5.27695 18" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

const SubPageNavbar = () => {

  const location = useLocation();

  const isActive = (path) => location.pathname == path;

  const navs = [
    {
      path: '/dashboard',
      label: 'My Dashboard',
      Icon: DashboardIcon
    },
    {
      path: '/search',
      label: 'Search',
      Icon: SearchIcon
    },
    {
      path: '/teammate-marketplace',
      label: 'Teammate Marketplace',
      Icon: PartnerIcon
    },
    {
      path: '/learn',
      label: 'Learn',
      Icon: LearnIcon
    },
    {
      path: '/account',
      label: 'Account',
      Icon: AccountIcon
    }
  ];

  return (
    <nav className="sub-page-tabs-container">
      {
        navs.map(({ path, label, Icon }, index) => (
          <Link key={path} className={`sub-page-tab-link ${isActive(path) ? 'active' : ''}`} to={path}>
            <Icon active={isActive(path)}/>
            <p className="sub-page-tab-link__text">{label}</p>
          </Link>
        ))
      }
    </nav>
  )
}

export default SubPageNavbar