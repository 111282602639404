import React, { useEffect, useState } from 'react'

import UserPageTemplate from './templates/UserPageTemplate';
import API from '../../utils/api';
import UserCustomButton, { USER_CUSTOM_BUTTON_TYPE_OUTLINE } from '../../components/user/UserCustomButton';
import UserCustomInput from '../../components/user/UserCustomInput';

import './Learn.css';
import ContentItem from '../../components/user/ContentItem';
import ArtifactItem from '../../components/user/ArtifactItem';

const Learn = () => {

  const [channels, setChannels] = useState([]);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [artifacts, setArtifacts] = useState([]);

  useEffect(() => {
    async function fetchChannels() {
      try {
        const response = await API.get('/api/protected/channels/all');
  
        if (response.success) {
          setChannels(response.channels.map((channel) => ({...channel, active: false})));
        }
  
      } catch(error) {
        console.log('error:', error);
      }
    }

    fetchChannels();
  }, [])

  useEffect(() => {
    async function fecthArtifactsByChannel() {
      try {
        const response = await API.get('/api/protected/artifacts/channel/' + selectedChannelId);
  
        if (response.success) {
          setArtifacts(response.artifacts);
        }
  
      } catch(error) {
        console.log('error:', error);
      }
    }

    async function fecthAllArtifacts() {
      try {
        const response = await API.get('/api/protected/artifacts/channel/all');
  
        if (response.success) {
          setArtifacts(response.artifacts);
        }
  
      } catch(error) {
        console.log('error:', error);
      }
    }

    if (selectedChannelId != null) {
      fecthArtifactsByChannel();

    } else {
      fecthAllArtifacts();
    }
    
  }, [selectedChannelId])

  const changeChannel = (channelId) => {
    setSelectedChannelId(channelId);

    setChannels((prevChannels) =>
      prevChannels.map((channel) => 
        channel.id == channelId ? {...channel, active : true} : {...channel, active: false}
      )
    );
  }

  return (
    <UserPageTemplate>
      <div className="user-dashboard-content">
        <div className="user-learn__body">
          <div className="user-learn__side">
            <div className="user-learn__filter-wrap">
              <div className="user-learn__filter-title">Learn About</div>
              <div className="user-learn__filters">
                {
                  channels.map((channel, index) => (
                    <div key={index} className={channel.active ? "user-learn__filter active" : "user-learn__filter"} onClick={() => changeChannel(channel.id)}>{channel.name}</div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="user-learn__main">
            <div className="user-learn__videos-grid">
              {
                artifacts.map((artifact, index) => <ArtifactItem key={index} data={artifact} featureImgUrl="/img/video-feature1.png" />)
              }

              {
                artifacts.length == 0 && (<>No Data</>)
              }
            </div>
          </div>
        </div>
      </div>
    </UserPageTemplate>
  )
}

export default Learn