import React from 'react';

import CustomButton from '../components/CustomButton';

import './ExplorerSection.css';

const ExplorerSection = () => {
  return (
    <section className="explorer-section">
      <img className="explorer-section__satellite-img" src="/img/satellite.svg" alt="satellite image" />
      <img className="explorer-section__target-img" src="/img/target1.svg" alt="target image" />
      <div className="container">
        <h1 className="page-title color-white">Find teammates to accelerate your growth into global markets</h1>
        <CustomButton label="explore more" type="button" isFullWidth={false} edgeColor="var(--color-neavy-blue)" />
        <img className="explorer-section__main-img" src="/img/explorer-main.png" alt="main explorer image" />
      </div>
    </section>
  );
};

export default ExplorerSection;