import React from 'react';

import './LabelSection.css';

const LabelSection = ({ labelFront, labelBehind }) => {

  return (
    <section className="label-section">
      <div className="label-section-wrap">
        <h1 className="label-behind">{ labelBehind }</h1>
        <h1 className="label-front">{ labelFront }</h1>
      </div>
    </section>
  );
};

export default LabelSection;