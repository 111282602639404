import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const titles = {
      "/": "Home - DefTechLink",
      "/login": "Login - DefTechLink",
      "/login-signup": "Login & Signup - DefTechLink",
      "/signup": "Signup - DefTechLink",
      "/set-password": "Set Password - DefTechLink",
      "/choose-plan": "Choose Plan - DefTechLink",
      "/checkout": "Checkout - DefTechLink",
      "/payment-success": "Payment Success - DefTechLink",
      "/pricing": "Pricing - DefTechLink",
      "/contact": "Contact - DefTechLink",
      "/dashboard": "Dashboard - DefTechLink",
      "/search": "Search - DefTechLink",
      "/partner-marketplace": "Partner Marketplace - DefTechLink",
      "/learn": "Learn - DefTechLink",
      "/account": "Account - DefTechLink",
    };

    document.title = titles[location.pathname] || "DefTechLink";
  }, [location.pathname]);

  return null;
};

export default PageTitle;