import React, { useState } from 'react';

import CustomInput from '../components/CustomInput';
import LabelSection from '../sections/LabelSection';
import CustomButton from '../components/CustomButton';

const ContactFormSection = () => {
  const [submittedValue, setSubmittedValue] = useState('');

  const handleInputChange = (value) => {
    console.log('Current Input Value:', value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedValue(e.target.value);
  };

  return (
    <section className="contact-form-section">
      <LabelSection labelFront="get in touch" labelBehind="contact us"/>
      <div className="contact-form-wrap align-center">
        <form className="form-content" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-col">
              <CustomInput
                type="text"
                placeholder="Name"
                initialValue=""
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <CustomInput
                type="email"
                placeholder="Email"
                initialValue=""
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row-cols-2">
            <div className="form-col">
              <CustomInput
                type="text"
                placeholder="Phone #"
                initialValue=""
                onInputChange={handleInputChange}
              />
            </div>
            <div className="form-col">
              <CustomInput
                type="text"
                placeholder="Location"
                initialValue=""
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <CustomInput
                type="textarea"
                placeholder="Message"
                initialValue=""
                onInputChange={handleInputChange}
                endIconClickEvent={() => {}}
              />
            </div>
          </div>
          <div className="form-row align-right">
            <CustomButton label="contact us" isFullWidth={false} edgeColor="#ffffff" />
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactFormSection;