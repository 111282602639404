import React, { useState } from 'react';

import UserCustomButton from '../../../components/user/UserCustomButton';
import UserCustomInput from '../../../components/user/UserCustomInput';
import { companyTypes, billingCycles } from '../../../constants/constants';

import './PersonalDetails.css';

const PersonalDetails = () => {

  const [formData, setFormData] = useState({ 
    'first_name': '',
    'last_name': '',
    'dob': '',
    'company_name': '',
    'company_address': '',
    'company_type': '',
    'billing_cycle': '',
    'saved_card': ''
  });

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="user-account-page">
      <h2 className="user-account-page-title">Personal Details</h2>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="text" label="First Name" placeholder="Emily" name="first_name" onInputChange={handleInputChange}/>
        </div>
        <div className="form-col">
          <UserCustomInput type="text" label="Last Name" placeholder="Cooper" name="last_name" onInputChange={handleInputChange}/>
        </div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="text" label="Date of Birth" placeholder="12/06/24" name="dob" onInputChange={handleInputChange}/>
        </div>
        <div className="form-col">
          <UserCustomInput type="text" label="Company Name" placeholder="H1B" name="company_name" onInputChange={handleInputChange}/>
        </div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="text" label="Company Address" placeholder="" name="company_address" onInputChange={handleInputChange}/>
        </div>
        <div className="form-col">
          <UserCustomInput
            type="select"
            label="Company type"
            options={companyTypes}
            initialValue=""
            name="company_type"
            onInputChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput
            type="select"
            label="Billing Cycle"
            options={billingCycles}
            initialValue=""
            name="billing_cycle"
            onInputChange={handleInputChange}
          />
        </div>
        <div className="form-col"></div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="text" label="Saved Credit Card Details" placeholder="" name="saved_card" onInputChange={handleInputChange}/>
          <div className="add-card-btn-wrap">
            <span className="add-card-btn">+ Add New Card</span>
          </div>
        </div>
        <div className="form-col"></div>
      </div>
      <div className="align-center">
        <UserCustomButton label="Edit" iconImg="/img/icon-pen.svg" />
      </div>
    </div>
  );
};

export default PersonalDetails;