import React, { useState, useEffect } from 'react';

import UserCustomButton from '../../../components/user/UserCustomButton';
import UserCustomInput from '../../../components/user/UserCustomInput';
import API from '../../../utils/api';
import { serviceSpecializations, interestedTechnologies, companyTypes } from '../../../constants/constants';

const ProfileSettings = () => {

  const [formData, setFormData] = useState({ 
    'interest_area': '',
    'company_type': '',
    'service_specialization': '',
    'city': '',
    'country': '',
    'company_logo_file': null,
    'company_logo_preview': '',
    'personal_logo_file': null,
    'personal_logo_preview': '',
    'offering': '',
    'previous_wins': '',
    'company_summary': ''
  });

  const [isVisibleMarketplace, setIsVisibleMarketPlace] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  useEffect(() => {

    if (formData.company_type == 'technology_developer') {
      setIsVisibleMarketPlace(true);

    } else {
      setIsVisibleMarketPlace(false);
    }
  }, [formData.company_type])

  const submitForm = async () => {
    try {
      const apiFormData = new FormData();

      if (formData['company_logo_file']) {
        apiFormData.append('files', formData['company_logo_file']);
      }

      if (formData['personal_logo_file']) {
        apiFormData.append('files', formData['personal_logo_file']);
      }

      const response = await API.put('/api/protected/account/profile', apiFormData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

    } catch(error) {
    }
  }

  return (
    <div className="user-account-page">
      <h2 className="user-account-page-title">Profile Settings</h2>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput
            type="select"
            label="Technology Area of Interest"
            options={interestedTechnologies}
            initialValue=""
            name="interest_area"
            onInputChange={handleInputChange}
          />
        </div>
        <div className="form-col"></div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput
            type="select"
            label="Company Type"
            options={companyTypes}
            initialValue=""
            name="company_type"
            onInputChange={handleInputChange}
          />
        </div>
        <div className="form-col"></div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="file" label="Company Logo" name="company_logo" preview={formData['company_logo_preview']} onInputChange={handleInputChange}/>
        </div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="textarea" placeholder="" label="Company Summary" name="company_summary" rows="5" onInputChange={handleInputChange}/>
        </div>
      </div>
      {
        isVisibleMarketplace && (
          <>
            <h2 className="user-account-page-title">Marketplace display settings</h2>
            <div className="form-row-cols-2">
              <div className="form-col">
                <UserCustomInput
                  type="select"
                  label="Service Specialization"
                  options={serviceSpecializations}
                  initialValue=""
                  name="service_specialization"
                  onInputChange={handleInputChange}
                />
              </div>
              <div className="form-col"></div>
            </div>
            <div className="form-row-cols-2">
              <div className="form-col">
                <UserCustomInput type="text" label="City" placeholder="" name="city" onInputChange={handleInputChange}/>
              </div>
            </div>
            <div className="form-row-cols-2">
              <div className="form-col">
                <UserCustomInput type="text" label="Country" placeholder="" name="country" onInputChange={handleInputChange}/>
              </div>
            </div>      
            
            <div className="form-row-cols-2">
              <div className="form-col">
              <UserCustomInput type="file" label="Personal Logo" name="personal_logo" preview={formData['personal_logo_preview']} onInputChange={handleInputChange}/>
              </div>
            </div>
            <div className="form-row-cols-2">
              <div className="form-col">
                <UserCustomInput type="text" label="Offering" placeholder="" name="offering" onInputChange={handleInputChange}/>
              </div>
            </div>
            <div className="form-row-cols-2">
              <div className="form-col">
                <UserCustomInput type="text" label="Previous Wins" placeholder="" name="previous_wins" onInputChange={handleInputChange}/>
              </div>
            </div>
          </>
        )
      }

      <div className="align-center">
        <UserCustomButton label="Edit" iconImg="/img/icon-pen.svg" onClick={submitForm}/>
      </div>
    </div>
  );
};

export default ProfileSettings;