import React from 'react';
import { Link } from 'react-router-dom';

import MainPageTemplate from '../templates/MainPageTemplate';
import HeadSection from '../sections/HeadSection';

const PaymentSuccess = () => {
  
  return (
    <MainPageTemplate>
      <HeadSection title="Payment Success"/>
      <div className="container">
        <div className="align-center py-100">
          <Link className="custom-button" to="/login">Login</Link>
        </div>        
      </div>
    </MainPageTemplate>
  )
}

export default PaymentSuccess