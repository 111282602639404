import React, { useState } from 'react';

import UserCustomButton from '../../../components/user/UserCustomButton';
import UserCustomInput from '../../../components/user/UserCustomInput';

const AccountSupport = () => {

  const [formData, setFormData] = useState({ 
    'message': ''
  });
    
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="user-account-page">
      <h2 className="user-account-page-title">Account Support</h2>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="textarea" placeholder="Hi DTL, I need help getting my teams logos on my account" name="message" rows="10" onInputChange={handleInputChange}/>
        </div>
      </div>
      <div className="align-center">
        <UserCustomButton label="Send" iconImg="/img/icon-pen.svg" />
      </div>
    </div>
  );
};

export default AccountSupport;