import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { PlanContext } from './context/PlanContext';
import PageTitle from "./components/PageTitle";
import LoginSignup from "./pages/LoginSignup";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SetPassword from "./pages/SetPassword";
import ChoosePlan from "./pages/ChoosePlan";
import Checkout from "./pages/Checkout";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Dashboard from "./pages/user/Dashboard";
import Search from "./pages/user/Search";
import TeammateMarketPlace from "./pages/user/TeammateMarketPlace";
import Learn from "./pages/user/Learn";
import Account from "./pages/user/Account";
import ProtectedRoute from "./components/ProtectedRoute";
import API from './utils/api';

import './App.css';
import PaymentSuccess from './pages/PaymentSuccess';
import TeammateDetails from './pages/user/TeammateDetails';

function App() {

  const [plans, setPlans] = useState(null);
  const [auth0, setAuth0] = useState(null);

  useEffect(() => {

    async function fecthPlans() {
      try {
        const response = await API.get('/api/plans/all');
  
        setPlans(response);
  
      } catch(error) {
        console.log('error:', error);
      }
    }
    
    async function fecthConfig() {
      try {
        const response = await API.get('/api/config/all');
  
        setAuth0(response['auth0']);
  
      } catch(error) {
        console.log('error:', error);
      }
    }

    fecthPlans();
    fecthConfig();
  }, []);

  return (
    <div className="App">
      {
        auth0 && <Auth0Provider
          domain={auth0['domain']}
          clientId={auth0['client_id']}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: auth0['audience'],
          }}
        >
          { 
          plans && <PlanContext.Provider value={plans}>
            <BrowserRouter>
              <PageTitle />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login-signup" element={<LoginSignup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/set-password" element={<SetPassword />} />
                <Route path="/choose-plan" element={<ChoosePlan />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />

                <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
                <Route path="/search" element={<Search />} />
                <Route path="/teammate-marketplace" element={<TeammateMarketPlace />} />
                <Route path="/teammate-marketplace/:id" element={<TeammateDetails />} />
                <Route path="/learn" element={<Learn />} />
                <Route path="/account" element={<Account />} />
              </Routes>
            </BrowserRouter>
          </PlanContext.Provider>
          }
        </Auth0Provider>
      }
    </div>
  );
}

export default App;
