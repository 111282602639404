import React from 'react';
import { Link } from 'react-router-dom';

import { PLAN_PERIOD_MONTHLY } from '../constants/constants';

import './PlanCard.css';

const PlanCard = ({ size, plan, currency, period }) => {
  return (
    <div className="plan-card-wrap">
      <div className="plan-card__level-wrap">
        <span className="plan-card__level">{ plan.level }</span>
      </div>
      <div className="plan-card__price-wrap">
        <span className="plan-card__price-value">${ period == PLAN_PERIOD_MONTHLY ? plan.price : parseFloat((plan.price * 10).toFixed(2)) }</span>
        <span className="plan-card__currency">{ currency }</span>
      </div>
      <p className="plan-card__description">{ plan.description }</p>
      <Link to={`/signup?size=${size}&plan=${plan.id}&period=${period}`} className="plan-card__btn">sign up</Link>
    </div>
  );
};

export default PlanCard;