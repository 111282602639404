import React from 'react';

import MainPageTemplate from '../templates/MainPageTemplate';
import HeadSection from '../sections/HeadSection';
import PlanSection from '../sections/PlanSection';
import LabelSection from '../sections/LabelSection';

const Pricing = () => {
  
  return (
    <MainPageTemplate>
      <HeadSection title="pricing"/>
      <PlanSection />
      <LabelSection labelFront="pricing made easy" labelBehind="pricing"/>
    </MainPageTemplate>
  )
}

export default Pricing