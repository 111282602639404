import React from 'react';

import MainPageTemplate from '../templates/MainPageTemplate';
import HomeSliderSection from '../sections/HomeSliderSection';
import PlanSection from '../sections/PlanSection';
import LabelSection from '../sections/LabelSection';
import ContactFormSection from '../sections/ContactFormSection';
import AboutSection from '../sections/AboutSection';
import ExplorerSection from '../sections/ExplorerSection';

const Home = () => {
  
  return (
    <MainPageTemplate>
      <HomeSliderSection />
      <div id="about">
        <AboutSection />
      </div>      
      <div className="home-plane-section-group" id="services">
        <LabelSection labelFront="pricing made easy" labelBehind="pricing" />
      </div>
      <div id="pricing">
        <PlanSection />
      </div>
      <ExplorerSection />
      <div id="contact">
        <ContactFormSection />
      </div>
    </MainPageTemplate>
  )
}

export default Home