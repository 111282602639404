import { useState } from 'react';

const useCustomCheckbox = (initialValue = false) => {
  const [checked, setChecked] = useState(initialValue);

  const toggle = () => setChecked((prev) => !prev);

  return {
    checkedValue: checked,
    toggle,
    bind: {
      checked,
      onChange: () => toggle(),
    },
  };
};

export default useCustomCheckbox;