import React, { useState } from 'react';

import MainPageTemplate from '../templates/MainPageTemplate';
import HeadSection from '../sections/HeadSection';
import ContactFormSection from '../sections/ContactFormSection';

const Contact = () => {
  
  return (
    <MainPageTemplate>
      <HeadSection title="contact"/>
      <ContactFormSection />
    </MainPageTemplate>
  )
}

export default Contact