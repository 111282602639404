import React from 'react'

import BasicPageTemplate from '../templates/BasicPageTemplate';
import PlanSection from '../sections/PlanSection';

const ChoosePlan = () => {

  return (
    <BasicPageTemplate>
      <div className="choose-plan-wrap align-center">
        <h1 className="page-title">Choose your plan</h1>
        <p className="form-desc">If you have chosen our monthly subscription package you will be automatically billed each month. You can cancel at any time and will retain access for the remainder of the month you have paid for. If you have chosen annual billing you will be automatically billed annually. You can cancel at any time and will retain access for the remainder of the year you have paid for.</p>
        <PlanSection />
      </div>
    </BasicPageTemplate>
  )
}

export default ChoosePlan