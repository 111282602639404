import React, { useState } from 'react';

import UserCustomButton from '../../../components/user/UserCustomButton';
import UserCustomInput from '../../../components/user/UserCustomInput';

import { emailNotifications } from '../../../constants/constants';

const NotificationSettings = () => {

  const [formData, setFormData] = useState({ 
    'email': '',
    'email_notifications': ''
  });
  
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="user-account-page">
      <h2 className="user-account-page-title">Notification Settings</h2>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput type="text" label="Email for email notification" placeholder="" name="email" onInputChange={handleInputChange}/>
        </div>
        <div className="form-col"></div>
      </div>
      <div className="form-row-cols-2">
        <div className="form-col">
          <UserCustomInput
            type="select"
            label="Email Notifications"
            options={emailNotifications}
            initialValue=""
            name="email_notifications"
            onInputChange={handleInputChange}
          />
        </div>
        <div className="form-col"></div>
      </div>
      <div className="align-center">
        <UserCustomButton label="Edit" iconImg="/img/icon-pen.svg" />
      </div>
    </div>
  );
};

export default NotificationSettings;